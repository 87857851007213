import React from 'react'
import Header from './Header'
import Footer from './Footer'
const Aboutus =()=>{
    return (
        <div>
          <Header />
          <div className="wrapper">
          <section className="banner1">
        
        <div className="heading">
            <h1>About Us</h1>
        </div>
        <p>e-Fluent, a unit of Simran Shri Shri International (Noida), is your go-to partner for polishing your English communication skills. An engaging and innovative app: its user-friendly features, easy-to-use interface, and immersive games bring forth a reliable path to assured success, exclusively focusing on verbal aspects of communication. We at e-Fluent aim at making English communication easy to understand and apply. Whether you are a beginner who is grappling with the challenges of communicating in English or an advanced-level learner who is looking forward to honing your skills and adding the charm of effective communication, we have your back!
.</p>
<div className="heading"><h1>Vision</h1> </div>
    <p>At e-Fluent, we consistently aim to uphold the highest regard for our users and learners. The app was created with one single but powerful thought: English for all. To empower non-native speakers with the necessary and sought-after skill of effective communication and transform them into global citizens who are confident and fluent speakers. Words are powerful, and so is inserting them at appropriate occasions and situations; hence, e-Fluent ensures that the users leave an indelible mark both personally and professionally.</p>

    <div className="heading"><h1 className="heading">Mission</h1></div>
    <p>e-Fluent’s objective is to provide a comprehensive platform for English speaking practice, including learner-centered calling modules and interactive tools to improve language ability. We seek to offer a welcoming and encouraging learning environment in which users may improve their speaking abilities through interesting chats, real-life circumstances, and individualized feedback. Our mission is to provide accessible English learning to create global citizens who stand out from the crowd with their impeccable communication and confidence. We firmly believe that your skills should complement you and help you achieve your goals. To be free of inhibitions, fears, and hurdles that come in the way of effective English communication and your ticket to getting recognized better—that's what e-Fluent proudly stands for!</p>
    
    <p><b>Integrity and Ethics:</b> Integrity and ethical conduct are non-negotiable values for us. We maintain the highest standards of honesty, transparency, and fairness in all our interactions. Our commitment to ethics extends to our tutors, staff, and the educational materials we provide, ensuring trust and reliability in our services.</p>
    
    <p>At our company, we are committed to ethical business practices and consumer protection:- <br />
    • We strictly refrain from engaging in any form of "money circulation scheme" and prioritize honest representation of our products and services. <br />
    • We uphold the security and privacy of consumer data, storing sensitive information within the jurisdiction of India as per applicable laws.<br />
    • Our dedication to customer satisfaction includes acknowledging and resolving complaints within 48 working hours, appointing a concerned department person for compliance oversight, and facilitating cancellation and refund processes as per our refund policy.<br />
    • We condemn unfair trade practices and ensure that our operations are transparent and focused on providing genuine value to our customers.</p>
        
    </section>
          </div>
        
          <Footer />
        </div>
      );
}

export default Aboutus;