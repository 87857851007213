import React from "react";
import calling from '../images/free-calling.webp' ;
import check from '../images/check.webp';
import Download from '../images/download.png';
import { APP_LINK } from "../utils/constants";

function FreeCalling(){
    return(
        <section className="FreecallingHolder " id="feature">
            <div className="wrapper">
                <div className="callingBox ">
                    <div className="callLeft">
                        <img src={calling} alt=""/>
                    </div>
                    {/* <div className="callRight">sa cdx sca</div> */}
                    <div className="whyCooseRight">
                        <h2>  Free Learner To Learner Calling</h2>
                        <div className="freeText">
                            <div className="checkIcon"><img src={check} alt=""/></div>
                            <div className="checkText"><p>Immerse yourself in the joy of learning and 'Practice English Speaking' together. Connect with your fellow co-learners for a spirited 30-minute talk— to practice English speaking with the first 30 minutes absolutely free! </p></div>
                            

                        </div>

                        <div className="freeText">
                            <div className="checkIcon"><img src={check} alt=""/></div>
                            <p>The learner to learner calling feature gives you access to curated modules and topics that co-learners can use as reference talk points.
 </p>

                        </div>

                        <div className="freeText">
                            <div className="checkIcon"><img src={check} alt=""/></div>
                            <p>Our calling feature enables a self-practice platform for learners for all levels. </p>

                        </div>

                        
                        <div className="freeText">
                            <div className="checkIcon"><img src={check} alt=""/></div>
                            <p>Practicing English combined with the ease of talking to like-minded learners makes it enjoyable and impactful!
 </p>

                        </div>


                     


                      <div className="downLoadBtn">
                        <a href={APP_LINK} target="_blank"><img src={Download} alt=""/></a>
                      </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default FreeCalling