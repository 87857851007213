import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { API_URL_AUTH } from "../utils/constants";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from 'react-toastify';
<ToastContainer
    autoClose={5000}
    hideProgressBar={true}
/>


const DeleteAccount = () => {

    const notify = () => {
        toast.success("Congrats! We have received your account deletion request. Our agent will be contact you shortly.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: true
        });
    }

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [category, setCategory] = useState('');
    const [description, setDescription] = useState('');
    const [nameErr, setEmailErr] = useState('');
    const [passwordErr, setPasswordErr] = useState('');
    const [categoryErr, setCategoryErr] = useState('');
    const [descriptionErr, setDescriptionErr] = useState('');

    const collectData = async (e) => {
        e.preventDefault();

        if(email === ''){
            setEmailErr('Email is required')
            return false;
        }else {
            setEmailErr('')
        }

        if(password === ''){
            setPasswordErr('Password is required')
            return false;
        }else {
            setPasswordErr('')
        }

        if(category === ''){
            setCategoryErr('category is required')
            return false;
        }else {
            setCategoryErr('')
        }

        if(description === ''){
            setDescriptionErr('Description is required')
            return false;
        }else {
            setDescriptionErr('')
        }

        let result = await fetch(`${API_URL_AUTH}delete-account-request`,
            {
                method: 'post',
                body: JSON.stringify({ email: email, password: password,category : category, description: description }),
                headers: { 'Content-Type': 'application/json' },
            });
        result = await result.json();
        console.log(result);
        notify();
    }
    return (
        <>
            <div className="account">
                <div className="wrapper">
                    <Header />
                    <div className="stayTuned">
                        <h2>Account Deletion Request</h2>
                        <div className="stayTunedHolder">
                            <div className="stayRight">
                                <div className="stayTunedForm">
                                    <form>
                                        <div className="from-filed">
                                            <input type="text" placeholder="Email" name="email" id="email" value={email} onChange={(e)=>setEmail(e.target
                                                .value)} />
                                        </div>
                                        <p className="blankErrMsg">{nameErr}</p>
                                        <div className="from-filed">
                                            <input type="password" placeholder="Password" name="password" id="password" value={password} onChange={(e)=>setPassword(e.target
                                                .value)}/>
                                        </div>
                                        <p className="blankErrMsg">{passwordErr}</p>
                                        <div className="from-filed">
                                            {/* <input type="text" placeholder="Email"/> */}
                                            <select name="category" id="category" value={category} onChange={(e)=>setCategory(e.target
                                                .value)}>
                                                <option value="">Reason of Deletion</option>
                                                <option value="1">Want to remove something</option>
                                                <option value="2">Privacy Concern</option>
                                                <option value="3">Data Concern</option>
                                                <option value="4">Not Useful</option>
                                                <option value="5">Other</option>
                                            </select>
                                        </div>
                                        <p className="blankErrMsg">{categoryErr}</p>
                                        <div className="from-filed">
                                            <textarea className="textArea" placeholder="Message" rows="4" value={description} onChange={(e)=>setDescription(e.target
                                                .value)}/>
                                        </div>

                                        <p className="blankErrMsg">{descriptionErr}</p>

                                        <div className="from-filed2 stayBtn">
                                            <button type="submit" className="" onClick={collectData}> Send Message </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default DeleteAccount;