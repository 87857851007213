import React, { useState } from "react";
import HomeIcon from '../images/home.png';
import CallIcon from '../images/call.png';
import MailIcon from '../images/mail.png';
import Website from '../images/global.png';
import Youtube from '../images/youtube.png';
import Facebook from '../images/facebook.png';
import Instagram from '../images/instagram.png';
import { API_URL_AUTH } from "../utils/constants";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from 'react-toastify';

<ToastContainer
    autoClose={5000}
    hideProgressBar={true}
/>

function StayTuned() {

    const notify = () => {
        toast.success("Congrats! We have received your inquiry, our agent will connect you shortly.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: true
        });
    }

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [description, setDescription] = useState('');
    const [nameErr, setNameErr] = useState('');
    const [emailErr, setEmailErr] = useState('');
    const [descriptionErr, setDescriptionErr] = useState('');

    const collectData = async (e) => {
        e.preventDefault();

        if(name === ''){
            setNameErr('Name is required')
            return false;
        }else {
            setNameErr('')
        }

        if(email === ''){
            setEmailErr('Email is required')
            return false;
        }else {
            setEmailErr('')
        }

        if(description === ''){
            setDescriptionErr('Description is required')
            return false;
        }else {
            setDescriptionErr('')
        }

        

        //console.log(name, email, description);

        let result = await fetch(`${API_URL_AUTH}enquiry`,
            {
                method: 'post',
                body: JSON.stringify({ name: name, email: email, description: description }),
                headers: { 'Content-Type': 'application/json' },
            });
        result = await result.json();
        //console.log(result);
        setName('');
        setEmail('');
        setDescription('');
        notify();
    }


    return (
        <div className="stayTuned" id="contact-us">
            <div className="wrapper">
                <h2>Contact Us</h2>
                <p>If you're looking for an English learning journey that's straightforward, fun and gets you talking like a pro, e-Fluent is your go-to buddy. It's not just an app; it's your ticket to excellence in speaking English! Leave the fear of speaking behind and download the app to begin your fluency journey!
</p>
                <div className="stayTunedHolder">

                    <div className="stayLeft">
                    <p><b>(Unit of Simran Shri Shri International Pvt. Ltd.)</b></p>
                        <div className="AddressBox">
                            
                            <div className="detailLeft"> <img src={HomeIcon} alt="" /> </div>
                            <div className="detailRight"> 
                            
                            <p>

                                
                                Head Office: G-24, 1st Floor, Sector 6, Noida UP, 201301 </p> </div>
                        </div>

                        <div className="AddressBox">
                            <div className="detailLeft"> <img src={CallIcon} alt="" /> </div>
                            <div className="detailRight"> <a href="tel:+91-7827764438"><p> +91-7290849821 </p></a> </div>
                        </div>

                        <div className="AddressBox">
                            <div className="detailLeft"> <img src={MailIcon} alt="" /> </div>
                            <div className="detailRight"> <a href="mailto:help@efluent.in"><p> help@efluent.in </p></a> </div>
                        </div>

                        <div className="AddressBox">
                            <div className="detailLeft"> <img src={Website} alt="" /> </div>
                            <div className="detailRight"> <p> www.efluent.in </p> </div>
                        </div>

                        <div className="socialIcon">
                            <h3>Click To Join</h3><br/>
                            <a href="https://www.youtube.com/@efluent"><img src={Youtube} alt="" /></a>
                            <a href="https://www.facebook.com/people/E-fluent/61553279916259/"><img src={Facebook} alt="" /></a>
                            <a href="https://www.instagram.com/practice_english_speaking_/"><img src={Instagram} alt="" /></a>
                        </div>
                    </div>
                    <div className="stayRight">
                        <div className="stayTunedForm">
                            <form>
                                <div className="from-filed">
                                    <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} required />
                                </div>
                                <p className="blankErrMsg">{nameErr}</p>    
                                <div className="from-filed">
                                    <input type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                </div>
                                <p className="blankErrMsg">{emailErr}</p>    
                                <div className="from-filed">
                                    <textarea className="textArea" placeholder="Message" value={description} rows="4" onChange={(e) => setDescription(e.target.value)} />
                                </div>
                                <p className="blankErrMsg">{descriptionErr}</p>
                                <div className="from-filed2 stayBtn">
                                    <button type="submit" className="" onClick={collectData}> Send Message </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default StayTuned