import React from "react";
import Data from './Data.json';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Client1 from '../images/harsh.png';
import Client2 from '../images/ankita.png';
import Client3 from '../images/manju.png';

function Testimonial(){
  console.log(Data)

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    slidesToScroll: 1
  };


    return(
        <section className="testimonials" id="reviews">
                <div className="wrapper">
                  <div className="heading">
                    <h2>Reviews</h2>
                  </div>

                  <Slider {...settings}>
          <div className="sliderBox">
          <div className="testimonialBox">
                      <div className="clientImg">
                        <img src={Client1} alt=" " />
                      </div>
                      <div className="name"> <h4>Harsh</h4> </div>
                      <div className="location"> <h5>Noida, India</h5> </div>
                      <div className="description"> <p>"e-Fluent has my heart! The free library resources are a goldmine. PDFs and infographics on grammar and 
vocabulary are super handy. This app turned learning into a fun journey. Two thumbs up!"</p> </div>
                      </div>
          </div>
          <div className="sliderBox">
          <div className="testimonialBox">
                      <div className="clientImg">
                        <img src={Client2} alt=" " />
                      </div>
                      <div className="name"> <h4>Ankita </h4> </div>
                      <div className="location"> <h5>Patna, Bihar</h5> </div>
                      <div className="description"> <p>"e-fluent stole my heart! The PDFs and infographics on vocabulary and literature are very useful. This app has changed how I approach learning; it's like turning English speaking into an exciting adventure. e-fluent deserves a standing ovation for making speaking English so engaging and accessible."</p> </div>
                      </div>
          </div>

          <div className="sliderBox">
          <div className="testimonialBox">
                      <div className="clientImg">
                        <img src={Client3} alt=" " />
                      </div>
                      <div className="name"> <h4>Manju </h4> </div>
                      <div className="location"> <h5>Mathura, UP</h5> </div>
                      <div className="description"> <p>"e-fluent is a true gem in the world of English Speaking! The availability of free resources in their library is remarkable. The collection of PDFs and infographics covering various subjects is a treasure trove for students.  Congratulations to e-fluent for making English speaking both accessible and enjoyable."</p> </div>
                      </div>
          </div>
         
        </Slider>

                </div>
        </section>
    )
}
export default Testimonial;