import React from 'react'
import Header from './Header';
import Footer from './Footer';
import AppBtn from '../images/app-btn.webp'; 
import Youtube from '../images/youtube2.webp';
import Facebook from '../images/facebook1.webp';
import Insta from '../images/instagram2.webp';
// import Whatsapp from '../images/whatsapp.webp';
import Call from '../images/call.webp';
import JoinLogo from '../images/join-logo.webp';
import JoinBtn from '../images/dw-btn.webp';
const JoinUs=()=>{
    return (
        <div>
          <Header />
         
          <section className="joinBanner">
          <div className="wrapper">
       
       <div className='joinHolder'>
        <div className='joinBox1'>
        <h2>Join Our e-Fluent Family</h2>
        <a href="https://play.google.com/store/apps/details?id=com.eFluent&pli=1" target="_blank"><img src={AppBtn} alt=""/></a>
        <div className='joinText'>
           <a href="https://www.youtube.com/@efluent" target="_blank"> <span><img src={Youtube} alt=""/></span> e-Fluent </a> 
        </div>


        <div className='joinText'>
        <a href="https://www.facebook.com/people/e-Fluent-Practice-English-Speaking/61560959875134/" target="_blank"> <span><img src={Facebook} alt=""/></span> e-Fluent </a> 
        </div>

        <div className='joinText'>
        <a href="https://www.instagram.com/practice_english_speaking_/" target="_blank"> <span><img src={Insta} alt=""/></span> practice_english_speaking_ </a> 
        </div>
{/* 
        <div className='joinText'>
        <a href="./" target="_blank"> <span><img src={Whatsapp} alt=""/></span> +91 782 776 4438 </a> 
        </div> */}

        <div className='joinText'>
        <a href="tel:+91-7290849821" target="_blank"> <span><img src={Call} alt=""/></span> +91 729 084 9821 </a> 
        </div>


        </div>
        <div className='joinBox1'>
        <div className='joinText'>
           <a href="https://www.efluent.in/" target="_blank"> <span><img className='imgWidth' src={JoinLogo} alt=""/></span>  </a> 
        </div>

        <div className='joinText'>
           <a href="https://play.google.com/store/apps/details?id=com.eFluent&pli=1" target="_blank"> <span><img className='imgWidth' src={JoinBtn} alt=""/></span>  </a> 
        </div>
        </div>
       </div>
       </div>
    </section>
         
        
          <Footer />
        </div>
    )
}

export default JoinUs;