import React from "react";
import logo from '../images/ft-logo.webp';
import Download from '../images/download.png';
import Heart from '../images/heart.png';
import { APP_LINK } from "../utils/constants";

const today = new Date();
const currentYear = today.getFullYear();
//console.log(currentYear);
function Footer(){
    return(

        <footer id="contact">
            <div className="wrapper">
                <div className="footerHolder">
                    <div className="footerBox1 footerLeft">
                        <img className="ftrLogo" src={logo} alt=""/>
                        {/* <p>With ease of conversation and features like learner-to-learner calling; you get to practice English speaking from the comfort of your home and an enriching user experience via gaming and self-paced courses. Come join us for a comprehensive learning journey!    </p> */}
                    </div>
                    <div className="footerRight">
                    <div className="footerBox2">
                       <h3>Useful Links</h3>
                       <ul>
                        <li><a href="/">Home</a></li>
                        <li><a href='/#feature'>Feature</a></li>
                <li><a href='/#whyChoosus'>Why Choose Us</a></li>
              
                <li><a href='/#contact-us'>Contact</a></li>
                <li><a href='/#reviews'>Review</a></li>
                       </ul>
                    </div>
                    <div className="footerBox2">
                    <h3>Other Links</h3>
                       <ul>
                        <li><a href="/privacy-policy" >Privacy Policy </a></li>
                        <li><a href="/terms-conditions" >Terms and Conditions </a></li>
                        <li><a href="/about-us" >About Us </a></li>
                       </ul>
                    </div>
                    <div className="footerBox2">
                    <h3>Download</h3>
                    <a href={APP_LINK} target="_blank"><img src={Download} alt=""/></a>
                    </div>
                    </div>
                  
                </div>
                <hr></hr>

                <div className="footerBottmon">
                    <div className="copyRight">
                    © Copyrights {currentYear} efluent All rights reserved.
                    </div>
                    <div className="madeBy">
                    Made with <span className="heart"><img src={Heart} alt=""/></span>       By efluent
                    </div>
                </div>
            </div>
        </footer>

    )
}
export default Footer;