import React  from "react";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
// import Slider from "react-slick";
// import Slide1 from '../images/screen1.webp';
// import Slide2 from '../images/screen2.webp';
// import Slide3 from '../images/screen3.webp';
// import Slide4 from '../images/screen4.webp';
// import Slide5 from '../images/screen5.webp';
import AppBg1 from '../images/appbg1.webp';
import AppBg2 from '../images/appbg2.webp';
import AppCenter from '../images/appMobile.webp'
import AppCenter2 from '../images/appMobile2.webp'
import AppCenter3 from '../images/appMobile3.webp'
import AppCenter4 from '../images/appMobile4.webp'
import AppCenter5 from '../images/appMobile5.webp'
import AppCenter6 from '../images/appMobile6.webp'
import AppCenter7 from '../images/appMobile7.webp'


function Appslider(){
  // console.log()

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    slidesToScroll: 1
  };

      return (
        <>
        <section className="appHolder">
          {/* <img className="appBg1" src={AppBg1} alt="" />
          <img className="appBg2" src={AppBg2} alt="" /> */}
          <div className="wrapper">
          <div className="appScreen">
                <h2>Explore Our App's Screenshots</h2>
                <p> Get your hands on other aspect overviews of our app.</p>
                </div>
              <div className="appBox">
              
                <div className="appBg1">
                <img  src={AppBg1} alt="" />
                </div>
                <div className="appCenter">
                <Slider {...settings}>
          <div className="sliderBox">
            <img src={AppCenter} alt="" />
          </div>
          <div className="sliderBox">
          <img src={AppCenter2} alt="" />
          </div>

          <div className="sliderBox">
          <img src={AppCenter3} alt="" />
          </div>

          <div className="sliderBox">
          <img src={AppCenter4} alt="" />
          </div>

          <div className="sliderBox">
          <img src={AppCenter5} alt="" />
          </div>

          <div className="sliderBox">
          <img src={AppCenter6} alt="" />
          </div>

          <div className="sliderBox">
          <img src={AppCenter7} alt="" />
          </div>
         
        </Slider>
                </div>
                <div className="appBg2">
                <img  src={AppBg2} alt="" />
                </div>

              </div>
          </div>
        </section>

          {/* <h2> Explore Our App's Screenshots </h2> */}
          {/* <section className="appslider">
          <div className="wrapper">
          <div className="appScreen">
                <h2>Explore Our App's Screenshots</h2>
                <p> Get your hands on other aspect overviews of our app.</p>
                </div>
          <Slider {...settings}>
          <div className="imgBox">
                        <img src={Slide1} alt=""/>
                    </div>
                    <div className="imgBox">
                        <img src={Slide2} alt=""/>
                    </div>
                    <div className="imgBox">
                        <img src={Slide3} alt=""/>
                    </div>
                    <div className="imgBox">
                        <img src={Slide4} alt=""/>
                    </div>
                    <div className="imgBox">
                        <img src={Slide5} alt=""/>
                    </div>
           
          </Slider>
        </div>
        </section> */}
        </>
       
      );
    }
    export default Appslider;