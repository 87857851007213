import React from "react";
import Navbar from "./Navbar";  


function Header() {
    return (
        <div id="header">
            <Navbar/>

        </div>
    );
}
export default Header;
