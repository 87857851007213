import React from 'react'
import GroupCall from '../images/group-call.webp'

function GroupCalling() {
  return (
    <>
    
    <section className='groupCallHolder'>
        <div className='wrapper'>
            <div className='groupCallBox'>
                <div className='groupLeft'>
                    <img src={GroupCall} alt=' ' />
                </div>
                <div className='groupRight'>
                   <h2>Group Calling or Chat Room Feature</h2>
                        <ul>
                            <li>Two heads are better than one.” You may agree that collaboration is
a crucial aspect of progressive learning. We understand this and
present to you the Chat Room feature. </li>
                            <li> Join ongoing discussions and forums via the Chat Room feature, wherein
experts will engage in thrilling discussions with the learners.</li>
                            <li>The chat room is a dedicated space for learning-oriented discussions.
Your learning will be put to the test and used. </li>
                            <li> Engage in riveting discussions, prove your mettle, and gain the confidence
to be a public speaker and for English conversation practice.</li>

                        </ul>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default GroupCalling