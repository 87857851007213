import React from 'react'
import Chat from '../images/chat.webp'
import Website from '../images/website.webp'
import User from '../images/user.webp'
import Game from '../images/game.webp'

function ExploreApp() {
  return (
    <>
        <section className='exploreHolder'>
            <div className='wrapper'>
                <div className='exploreBox'>
                    <div className='exploreRight'>
                    <h2>Explore Additional In-App Features</h2>

                    <ul>
                        <li>Conversation Topics and Dynamic Modules</li>
                        <li>Free Library Resources</li>
                        <li>Monthly Expert Query Solving Sessions</li>
                        <li>Free Games to Practice Grammar Concepts</li>

                    </ul>
                    <div className='expBtn'>
                        <a href='#' ><img src={Chat} alt=' ' /> <br/> Topic</a>
                        <a href='#' ><img src={Website} alt=' ' /> <br/> E-Library</a>
                        <a href='#' ><img src={User} alt=' ' /><br/> Experts</a>
                        <a href='#' ><img src={Game} alt=' ' /><br/> Games</a>
                    </div> 
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default ExploreApp