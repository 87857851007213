import React from "react";
import Header from "./Header";
import Footer from "./Footer";
const PrivacyPolicy = () => {
  return (
    <div>
      <div className="wrapper">
        <Header />
        <section className="banner1">
    
	  <div className="heading" >
	  	<h1 >PRIVACY POLICY</h1>
	  </div>
	  
	  <p>This privacy notice for Simran Shri Shri International Pvt. Ltd. (doing business as SSSi ) ('we', 'us', or 'our'), describes how and why we might collect, store, use, and/or share ('process') your information when you use our services ('Services'), such as when you:
Visit our website at http://www.efluent.in, or any website of ours that links to this privacy notice
Download and use our mobile application (efluent: English Speaking ), or any other application of ours that links to this privacy notice
Engage with us in other related ways, including any sales, marketing, or events
Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at help@efluent.in.
</p>



	  <h3>TABLE OF CONTENTS</h3>
	  
	  <h4>1. OUR SERVICES</h4>
	  <p>The information provided when using the Services is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Services from other locations do so on their own initiative and are solely responsible for compliance with local laws if and to the extent local laws are applicable.</p>
	  
	  

 <p>This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our table of contents below to find the section you are looking for.</p>

 <p>What personal information do we process? When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use. Learn more about personal information you disclose to us.</p>

 <p>Do we process any sensitive personal information? We do not process sensitive personal information.</p>

 <p>Do we receive any information from third parties? We do not receive any information from third parties.</p>

 <p>How do we process your information? We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about how we process your information.</p>

 <p>In what situations and with which types of parties do we share personal information? We may share information in specific situations and with specific categories of third parties. Learn more about when and with whom we share your personal information.</p>

 <p>How do we keep your information safe? We have organisational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Learn more about how we keep your information safe.</p>

 <p>What are your rights? Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about your privacy rights.</p>

 <p>How do you exercise your rights? The easiest way to exercise your rights is by visiting App/efluent/profile/edit , or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.</p>

 <p>Want to learn more about what we do with any information we collect? Review the privacy notice in full.</p>


<h2>TABLE OF CONTENTS</h2>




<h4>1. WHAT INFORMATION DO WE COLLECT?</h4>

<p>Personal information you disclose to us</p>

<p>In Short: We collect personal information that you provide to us.</p>

<p>We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</p>

<p>Personal Information Provided by You. The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</p>
Names<br/>
Gender<br/>
Phone numbers<br/>
Email addresses<br/>
Passwords<br/>
Profession<br/>
Qualification<br/>
Language<br/>
City<br/>

<p><b>Sensitive Information:</b> We require an OTP sent to your mobile number and email id to verify your mobile number and email id.<br/>
	  We require your OTP during payment to verify your transaction</p>

<p>Payment Data. We may collect data necessary to process your payment if you make purchases, such as your payment instrument number, and the security code associated with your payment instrument. Razorpay stores all payment data. You may find their privacy notice link(s) here: https://razorpay.com/privacy/.
</p>
<p>Application Data. If you use our application(s), we also may collect the following information if you choose to provide us with access or permission:<br/>
Mobile Device Access. We may request access or permission to certain features from your mobile device, including your mobile device's storage, microphone,  messages, calendar, call recording and other features. If you wish to change our access or permissions, you may do so in your device's settings.</p>

<p>Call recording permission: We may require your permission to record your call to evaluate your progress in learning. </p>

<p>Mobile Device Data. We automatically collect device information (such as your mobile device ID, model, and manufacturer), operating system, version information and system configuration information, device and application identification numbers, browser type and version, hardware model Internet service provider and/or mobile carrier, and Internet Protocol (IP) address (or proxy server). If you are using our application(s), we may also collect information about the phone network associated with your mobile device, your mobile device’s operating system or platform, the type of mobile device you use, your mobile device’s unique device ID, and information about the features of our application(s) you accessed.</p>

<p>Push Notifications: We may request to send you push notifications regarding your account or certain features of the application(s). If you wish to opt-out from receiving these types of communications, you may turn them off in your device's settings.<br/>
This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and reporting purposes.</p>

<p>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</p>

<h4>2. HOW DO WE PROCESS YOUR INFORMATION?</h4>

<p>In Short: We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</p>

<p>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</p>
To facilitate account creation and authentication and otherwise manage user accounts. We may process your information so you can create and log in to your account, as well as keep your account in working order.<p></p>
<p>To deliver and facilitate delivery of services to the user. We may process your information to provide you with the requested service.</p>
<p>To respond to user inquiries/offer support to users. We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.</p>
<p>To fulfil and manage your orders. We may process your information to fulfil and manage your orders, payments, returns, and exchanges made through the Services.</p>

<p>To enable user-to-user communications. We may process your information if you choose to use any of our offerings that allow for communication with another user.</p>


<p>To request feedback. We may process your information when necessary to request feedback and to contact you about your use of our Services.</p>

<p>To send you marketing and promotional communications. We may process the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt out of our marketing emails at any time. For more information, see 'WHAT ARE YOUR PRIVACY RIGHTS?' below.</p>
<p>To deliver targeted advertising to you. We may process your information to develop and display personalised content and advertising tailored to your interests, location, and more.</p>
<p>To post testimonials. We post testimonials on our Services that may contain personal information.</p>
<p>To administer prize draws and competitions. We may process your information to administer prize draws and competitions.</p>

<p>To evaluate and improve our Services, products, marketing, and your experience. We may process your information when we believe it is necessary to identify usage trends, determine the effectiveness of our promotional campaigns, and to evaluate and improve our Services, products, marketing, and your experience.</p>
<p>To identify usage trends. We may process information about how you use our Services to better understand how they are being used so we can improve them.</p>
<p>To determine the effectiveness of our marketing and promotional campaigns. We may process your information to better understand how to provide marketing and promotional campaigns that are most relevant to you.</p>

<h4>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h4>

<p>In Short: We may share information in specific situations described in this section and/or with the following categories of third parties.</p><p>

</p><p>Vendors, Consultants, and Other Third-Party Service Providers. We may share your data with third-party vendors, service providers, contractors, or agents ('third parties') who perform services for us or on our behalf and require access to such information to do that work. The categories of third parties we may share personal information with are as follows:</p>
Ad Networks<br/>
Data Analytics Services<br/>
Sales &amp; Marketing Tools<br/>
Cloud Computing Services<br/>

<p>We also may need to share your personal information in the following situations:</p>
<p>Business Transfers: We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</p>
<p>Affiliates: We may share your information with our affiliates, in which case we will require those affiliates to honour this privacy notice. Affiliates include our parent company and any subsidiaries, joint venture partners, or other companies that we control or that are under common control with us.
</p><p>Other Users: When you share personal information or otherwise interact with public areas of the Services, such personal information may be viewed by all users and may be publicly made available outside the Services in perpetuity. Similarly, other users will be able to view descriptions of your activity, communicate with you within our Services, and view your profile.</p>
<p>Offer Wall: Our application(s) may display a third-party hosted 'offer wall'. Such an offer wall allows third-party advertisers to offer virtual currency, gifts, or other items to users in return for the acceptance and completion of an advertisement offer. Such an offer wall may appear in our application(s) and be displayed to you based on certain data, such as your geographic area or demographic information. When you click on an offer wall, you will be brought to an external website belonging to other persons and will leave our application(s). A unique identifier, such as your user ID, will be shared with the offer wall provider in order to prevent fraud and properly credit your account with the relevant reward.</p>

<h4>4. HOW LONG DO WE KEEP YOUR INFORMATION?</h4>

In Short: We keep your information for as long as necessary to fulfil the purposes outlined in this privacy notice unless otherwise required by law.<p>

We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account with us.</p><p>

When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymise such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p><p>

</p><h4>5. HOW DO WE KEEP YOUR INFORMATION SAFE?</h4>

<p>In Short: We aim to protect your personal information through a system of organisational and technical security measures.</p><p>

</p><p>We have implemented appropriate and reasonable technical and organisational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</p><p>

</p><h4>6. WHAT ARE YOUR PRIVACY RIGHTS?</h4>

<p>In Short: You may review, change, or terminate your account at any time.</p>

<p>Withdrawing your consent: If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below.</p>

<p>However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</p>

<p>Opting out of marketing and promotional communications: You can unsubscribe from our marketing and promotional communications at any time by or by contacting us using the details provided in the section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below. You will then be removed from the marketing lists. However, we may still communicate with you — for example, to send you service-related messages that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.</p>

<p>Account Information</p>

<p>If you would at any time like to review or change the information in your account or terminate your account, you can:
Contact us using the contact information provided.</p>
<p>Log in to your account settings and update your user account.</p>
<p>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.</p>

<p>If you have questions or comments about your privacy rights, you may email us at help@efluent.in.</p>

<h4>7. CONTROLS FOR DO-NOT-TRACK FEATURES</h4>

<p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ('DNT') feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognising and implementing DNT signals has been finalised. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</p>

<h4>8. DO WE MAKE UPDATES TO THIS NOTICE?</h4>

<p>In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.</p>

<p>We may update this privacy notice from time to time. The updated version will be indicated by an updated 'Revised' date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</p>

<h4>9. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h4>

<p>If you have questions or comments about this notice, you may email us at help@efluent.in or contact us by post at:</p>

<p>
	<strong>
	Simran Shri Shri International Pvt. Ltd.<br/>
1st Floor, G-24<br/>
Sector-6<br/>
Noida, Uttar Pradesh 201301<br/>
India<br/>
help@efluent.in<br/>

	</strong>
</p>

<h4>10. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h4>

<p>Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please visit: App/efluent/profile/edit .
This privacy policy was created using Termly's Privacy Policy Generator.</p>

	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
  </section>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
