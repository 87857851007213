import React from 'react'
import Header from './Header';
import Footer from './Footer';
import BarCodeImg from '../images/nikita-mathur.jpg'

function BarCode() {
  return (
    <>
    <Header />
    <section className='barCodeHolder'>
        <div className='wrapper'>
            <div className='barCodeBox'>
                <div className='barCodeImg'>
                <img src={BarCodeImg} alt=' '/>
                </div>
            </div>
        </div>
    </section>
    <Footer />
    </>
  )
}

export default BarCode