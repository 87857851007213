import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from 'react-toastify';
import { API_URL_AUTH } from "../utils/constants";


<ToastContainer
    autoClose={5000}
    hideProgressBar={true}
/>

function Subscribe() {

    const notify = () => {
        toast.success("Congrats! You have subscribed successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: true
        });
    }
    const [email, setEmail] = useState("");
    const [email_error,setEmailError] = useState();

    const collectData = async (e) => {
        e.preventDefault();
        
        if(email === ''){
            setEmailError('Email is required')
            return false;
        }else {
            setEmailError('')
        }

        //console.log(email)
        let result = await fetch(`${API_URL_AUTH}subscribe`, {
            method: "post",
            //  mode: 'no-cors',
            body: JSON.stringify({ email: email }),
            headers: { 'Content-Type': "application/json" },
        });
        //result = await result.json()
        setEmail('');
        //console.log(result);
        notify();
    }
    //notify();
    <ToastContainer />

    return (

        <section className="subScribe">
            <div className="wrapper">
                <div className="subScribeBox">
                    <div className="subScribeHead"><h2>Subscribe to get updates</h2></div>

                    <p>Drop your email to receive information regarding app updates, promotions and offers!</p>
                </div>

                <div className="subScribeForm">
                    <form >
                        <div className="from-filed">
                            <input type="email" placeholder="Enter Your Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                            
                        </div>
                        <p className="blankErrMsg">{email_error}</p>
                        {/* <input type="text" placeholder="Enter Name"/> */}
                       
                        <div className="from-filed2 subScribeBtn">
                            <button type="submit" className="" onClick={collectData}> Subscribe </button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    )
}

export default Subscribe;