import React from "react";
import BoyCall from "../images/exp-call-left.webp";
import GirlCall from "../images/exp-call-right.webp";
import Online from '../images/online.webp'
import Expert from '../images/expert.webp'


function ExpertCalling() {
  return (
    <>
      <section className="expertCallHolder">
        <img className="expImgLeft" src={Online} alt=" "/>
        <img className="expImgRight" src={Expert} alt=" "/>
        <div className="wrapper">
          <div className="expertCallBox">
            <div className="expCallLeft">
              <img src={BoyCall} alt=" " />
            </div>
            <div className="expCallCenter">
              <h2>Learner To Expert Calling</h2>
              <ul>
                <li>If you are in search of an expert who can handhold you and teach
                you the basics of ‘how to talk in pitch-perfect English’, then
                you have arrived at your destination. </li>
                <li> Our learner-to-expert calling features enable you to dive deeper into the realm of spoken English and equip you with the necessary skills to make a mark!</li>
                <li>Explore learner-to-expert calling and get hands-on experience
concerning a wide variety of conversational aspects of the English
language.  </li>
                <li>The in-app progressive learning modules are ideated and created to help you navigate the treacherous path of self-learning and to practice English speaking. Handpicked session topics that you can discuss with your designated expert without paying a hefty subscription. </li>

              </ul>
             
            </div>
            <div className="expCallRight">
              <img src={GirlCall} alt=" " />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ExpertCalling;
