import React from "react";
import Banner from "./Banner";
import FreeCalling from "./FreeCalling";
import WhyCooseUs from "./WhyCooseUs";
// import Feature from "./Features";
import Footer from "./Footer";
import Subscribe from "./Subscribe";
import Testimonial from "./Testimonail";
import StayTuned from "./Staytuned";
import Appslider from "./AppScreenshot";
import Header from "./Header";
import ExploreApp from "./ExploreApp";
import ExpertCalling from "./ExpertCalling";
import GroupCalling from "./GroupCalling";
const Home = () => {
  return (
    <>
     
        <Header />
        
        <Banner />
      
     <ExpertCalling />
      <FreeCalling />
      <GroupCalling />

      <ExploreApp />
      {/* <Feature /> */}
      <WhyCooseUs />
      <Appslider />
      <Subscribe />
      <Testimonial />
      <StayTuned />
     
      <Footer />
     
    </>
  );
};

export default Home;
