import React from "react";
import WhychooseImg from '../images/wepik-export.webp';

function WhyCooseus(){
    return(

        <section className="whyCoose" id="whyChoosus">
            <div className="wrapper">
                <div className="whyCooseBox">
                    <div className="whyCooseLeft">
                        <img src={WhychooseImg} alt="" />
                    </div>
                    <div className="whyCooseRight">
                        <h2>Why Choose Us</h2>
                        <div className="chooseText">
                            <p>Imagine having a conversation companion accessible to help you improve your pronunciation, broaden your vocabulary, and perfect your grammar. e-Fluent's approach is pleasant, supportive, and flexible, catering to your unique learning style and speed.</p>

                        </div>

                        <div className="chooseText">
                            
<h3>Best App to Improve English Speaking Skills</h3>
<p>e-Fluent makes you a pro at speaking English. We focus specifically on enhancing speaking skills, making it the top choice for learners aiming to excel in this field.To summarize, we build confident and fluent English speakers.</p>
                        </div>

                        <div className="chooseText">
                          
<h3>Spoken English</h3>
<p>Comprehensive learning: It's like a buffet for your brain! e-Fluent serves up everything you need: grammar, vocabulary, reading, writing, and listening. You get a full plate of English goodness.
</p>
                        </div>

                        <div className="chooseText">
                          
                          <h3>Best Review Among Competitors</h3>
                          <p>Users have provided remarkable and positive feedback regarding the feasibility of the features and overall user- experience of e-Fluent. We have garnered the best reviews among our competitors, reflecting the satisfaction and success of our users.</p>
                                                  </div>

                                                  <div className="chooseText">
                          
                          <h3>Expert Interaction for Guidance
</h3>
                          <p> Ever wish you could ask a pro for help? Well, with e-Fluent, you can! Our expansive pool of seasoned experts is just a tap away. Connect with experts for a one-on-one or group discussion session to alleviate your learning curve.
</p>
                                                  </div>

                    </div>
                </div>
            </div>
        </section>
    )
}
export default WhyCooseus;