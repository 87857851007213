import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const TermsConditons = () => {
  return (
    <div>
      <Header />
      <div className="wrapper">
      <section className="banner1">
    
    <div className="heading">
        <h1 >TERMS AND CONDITIONS</h1>
    </div>
    
    <p>We are Simran Shri Shri International Pvt. Ltd., doing business as SSSi ('Company', 'we', 'us', or 'our'), a company registered in India at 1st Floor, G-24, Sector-6, Noida, Uttar Pradesh 201301.</p>

<p>We operate the website http://www.efluent.in (the 'Site'), the mobile application e-Fluent: English Speaking (the 'App'), as well as any other related products and services that refer or link to these legal terms (the 'Legal Terms') (collectively, the 'Services').</p>

<p>You can contact us by email at help@efluent.in or by mail to the 1st Floor, G-24, Sector-6, Noida, Uttar Pradesh 201301, India.</p>

<p>These Legal Terms constitute a legally binding agreement made between you, whether personally or on behalf of an entity ('you'), and Simran Shri Shri International Pvt. Ltd. , concerning your access to and use of the Services. You agree that by accessing the Services, you have read, understood, and agreed to be bound by all of these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.</p>

<p>Supplemental terms and conditions or documents that may be posted on the Services from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Legal Terms at any time and for any reason. We will alert you about any changes by updating the 'Last updated' date of these Legal Terms, and you waive any right to receive specific notice of each such change. It is your responsibility to periodically review these Legal Terms to stay informed of updates. You will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any revised Legal Terms by your continued use of the Services after the date such revised Legal Terms are posted.</p>

<p>All users who are minors in the jurisdiction in which they reside (generally under the age of 18) must have the permission of and be directly supervised by their parent or guardian to use the Services. If you are a minor, you must tell your parent or guardian to read and agree to these Legal Terms prior to using the Services.</p>

<p>We recommend that you print a copy of these Legal Terms for your records.</p>

    <h3>TABLE OF CONTENTS</h3>
    
    <h4>1. OUR SERVICES</h4>
    <p>The information provided when using the Services is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Services from other locations do so on their own initiative and are solely responsible for compliance with local laws if and to the extent local laws are applicable.</p>
    
    <h4>2. INTELLECTUAL PROPERTY RIGHTS</h4>
    <p>The information provided when using the Services is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Services from other locations do so on their own initiative and are solely responsible for compliance with local laws if and to the extent local laws are applicable.</p>
    
    <h5>a. Our intellectual property</h5>
    <p>We are the owner or the licensee of all intellectual property rights in our Services, including all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics in the Services (collectively, the 'Content'), as well as the trademarks, service marks, and logos contained therein (the 'Marks').</p>

<p>Our Content and Marks are protected by copyright and trademark laws (and various other intellectual property rights and unfair competition laws) and treaties in India and around the world.</p>

<p>The Content and Marks are provided in or through the Services 'AS IS' for your personal, non-commercial use only.</p>

    
    
    <h5>b. Your use of our Services</h5>
    <p>Subject to your compliance with these Legal Terms, including the 'PROHIBITED ACTIVITIES' section below, we 
grant you a non-exclusive, non-transferable, revocable licence to:</p>
<p> Access the Services and download or print a copy of any portion of the Content to which you have properly    
 gained access.</p>
<p>Solely for your personal, non-commercial use.</p>

<p>Except as set out in this section or elsewhere in our Legal Terms, no part of the Services and no Content or 
Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, 
translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose 
whatsoever, without our express prior written permission.</p>

<p>If you wish to make any use of the Services, Content, or Marks other than as set out in this section or elsewhere 
in our Legal Terms, please address your request to: help@efluent.in. If we ever grant you permission to post, 
reproduce, or publicly display any part of our Services or Content, you must identify us as the owners or 
licensors of the Services, Content, or Marks and ensure that any copyright or proprietary notice appears or is 
visible on posting, reproducing, or displaying our Content.</p>

<p>We reserve all rights not expressly granted to you in and to the Services, Content, and Marks.</p>

<p>Any breach of these Intellectual Property Rights will constitute a material breach of our Legal Terms, and your 
right to use our Services will terminate immediately.</p>
    
    <h5>c. Your submissions and contributions</h5>
    <p>
        lease review this section and the 'PROHIBITED ACTIVITIES' section carefully prior to using our Services to understand the (a) rights you give us and (b) obligations you have when you post or upload any content through the Services.
    </p>
    
    <h5>c1. Submissions</h5>
    <p>
       By directly sending us any question, comment, suggestion, idea, feedback, or other information about the Services ('Submissions'), you agree to assign to us all intellectual property rights in such Submission. You agree that we shall own this Submission and be entitled to its unrestricted use and dissemination for any lawful purpose, commercial or otherwise, without acknowledgement or compensation to you. 
    </p>
    
    <h5>c2. Contributions</h5>
    <p>The Services may invite you to chat, contribute to, or participate in blogs, message boards, online forums, and other functionality during which you may create, submit, post, display, transmit, publish, distribute, or broadcast content and materials to us or through the Services, including but not limited to text, writings, video, audio, photographs, music, graphics, comments, reviews, rating suggestions, personal information, or other material ('Contributions'). Any Submission that is publicly posted shall also be treated as a Contribution.</p>
    
    <h5>c3. When you post Contributions, you grant us a licence (including use of your name, trademarks, and logos)</h5>
    <p>By posting any Contributions, you grant us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive, transferable, royalty-free, fully-paid, worldwide right, and licence to: use, copy, reproduce, distribute, sell, resell, publish, broadcast, retitle, store, publicly perform, publicly display, reformat, translate, excerpt (in whole or in part), and exploit your Contributions (including, without limitation, your image, name, and voice) for any purpose, commercial, advertising, or otherwise, to prepare derivative works of, or incorporate into other works, your Contributions, and to sublicence the licences granted in this section. Our use and distribution may occur in any media format and through any media channels.</p>

<p>This licence includes our use of your name, company name, and franchise name, as applicable, and any of the trademarks, service marks, trade names, logos, and personal and commercial images you provide.
</p>
    
    <h5>c4. You are responsible for what you post or upload</h5>
    <p>By sending us Submissions and/or posting Contributions through any part of the Services or making Contributions accessible through the Services by linking your account through the Services to any of your social networking accounts, you:</p>
    
    <p>Confirm that you have read and agree with our 'PROHIBITED ACTIVITIES' and will not post, send, publish, upload, or transmit through the Services any Submission nor post any Contribution that is illegal, harassing, hateful, harmful, defamatory, obscene, bullying, abusive, discriminatory, threatening to any person or group, sexually explicit, false, inaccurate, deceitful, or misleading;</p>

<p>To the extent permissible by applicable law, waive any and all moral rights to any such Submission and/or Contribution;</p>

<p>Warrant that any such Submission and/or Contributions are original to you or that you have the necessary rights and licences to submit such Submissions and/or Contributions and that you have full authority to grant us the above-mentioned rights in relation to your Submissions and/or Contributions; and</p>

<p>Warrant and represent that your Submissions and/or Contributions do not constitute confidential information.</p>


    
    
    <h5>c5. We may remove or edit your Content:</h5>
    <p>Although we have no obligation to monitor any Contributions, we shall have the right to remove or edit any Contributions at any time without notice if in our reasonable opinion we consider such Contributions harmful or in breach of these Legal Terms. If we remove or edit any such Contributions, we may also suspend or disable your account and report you to the authorities.
</p>
    
    <h5>c6. Copyright infringement</h5>
    <p>We respect the intellectual property rights of others. If you believe that any material available on or through 
the Services infringes upon any copyright you own or control, please immediately refer to the 'COPYRIGHT 
INFRINGEMENTS' section below.</p>
    
    
    <h4>3. USER REPRESENTATIONS</h4>
    <p>By using the Services, you represent and warrant that: </p>
(1) All registration information you submit will be true, accurate, current, and complete; <br/>
(2) You will maintain the accuracy of such information and promptly update such registration information as 
    necessary; <br/>
(3) You have the legal capacity and you agree to comply with these Legal Terms; <br/>
(4) You are not a minor in the jurisdiction in which you reside, or if a minor, you have received parental 
     permission to use the Services; <br/>
(5) You will not access the Services through automated or non-human means, whether through a bot, script or 
     otherwise; <br/>
(6) You will not use the Services for any illegal or unauthorised purpose; and <br/>
(7) Your use of the Services will not violate any applicable law or regulation.<br/>

<p>If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to 
suspend or terminate your account and refuse any and all current or future use of the Services (or any portion 
thereof).</p>
    
   <h4>4. USER REGISTRATION</h4>
    <p>You may be required to register to use the Services. You agree to keep your password confidential and will be 
responsible for all use of your account and password. We reserve the right to remove, reclaim, or change a 
username you select if we determine, in our sole discretion, that such username is inappropriate, obscene, or 
otherwise objectionable.</p>
    
    <h4>5. PURCHASES AND PAYMENT</h4>
    <p>We accept the following forms of payment:</p>

- Visa<br/>
- Mastercard<br/>
- American Express<br/>
- PayPal<br/>
- UPI<br/>
- Rupay<br/>

<p>You agree to provide current, complete, and accurate purchase and account information for all purchases made via the Services. You further agree to promptly update account and payment information, including email address, payment method, and payment card expiration date, so that we can complete your transactions and contact you as needed. Sales tax will be added to the price of purchases as deemed required by us. We may change prices at any time. All payments shall be in INR.</p>

<p>You agree to pay all charges at the prices then in effect for your purchases and any applicable shipping fees, and you authorise us to charge your chosen payment provider for any such amounts upon placing your order. If your order is subject to recurring charges, then you consent to our charging your payment method on a recurring basis without requiring your prior approval for each recurring charge, until such time as you cancel the applicable order. We reserve the right to correct any errors or mistakes in pricing, even if we have already requested or received payment.</p>

<p>We reserve the right to refuse any order placed through the Services. We may, in our sole discretion, limit or cancel quantities purchased per person, per household, or per order. These restrictions may include orders placed by or under the same customer account, the same payment method, and/or orders that use the same billing or shipping address. We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers, or distributors.
</p>
    
    <h4>6. POLICY</h4>
    <p>All sales are final, and no refund will be issued.</p>
    
    <h4>7. PROHIBITED ACTIVITIES</h4>
    <p>You may not access or use the Services for any purpose other than that for which we make the Services available. The Services may not be used in connection with any commercial endeavours except those that are specifically endorsed or approved by us.</p>

<p>As a user of the Services, you agree not to:</p>
        
        •   Systematically retrieve data or other content from the Services to create or compile, directly or indirectly, a 
   collection, compilation, database, or directory without written permission from us.<br/>
•   Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information 
   such as user passwords.<br/>
•   Circumvent, disable, or otherwise interfere with security-related features of the Services, including features 
   that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Services 
   and/or the Content contained therein.<br/>
•   Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services.<br/>
•   Use any information obtained from the Services in order to harass, abuse, or harm another person.<br/>
•   Make improper use of our support services or submit false reports of abuse or misconduct.<br/>
•   Use the Services in a manner inconsistent with any applicable laws or regulations.<br/>
•   Engage in unauthorised framing of or linking to the Services.<br/>
•   Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including 
   excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with any 
   party’s uninterrupted use and enjoyment of the Services or modifies, impairs, disrupts, alters, or interferes 
   with the use, features, functions, operation, or maintenance of the Services.<br/>
•   Engage in any automated use of the system, such as using scripts to send comments or messages or using 
   any data mining, robots, or similar data gathering and extraction tools.<br/>
•   Delete the copyright or other proprietary rights notice from any Content.<br/>
•   Attempt to impersonate another user or person or use the username of another user.<br/>
•   Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active 
   information collection or transmission mechanism, including, without limitation, clear graphics interchange 
   formats ('gifs'), 1×1 pixels, web bugs, cookies, or other similar devices (sometimes referred to as 'spyware' or 
   'passive collection mechanisms' or 'pcms').<br/>
•   Interfere with, disrupt, or create an undue burden on the Services or the networks or services connected to 
   the Services.<br/>
•   Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of 
   the Services to you.<br/>
•   Attempt to bypass any measures of the Services designed to prevent or restrict access to the Services or any 
   portion of the Services.<br/>
•   Copy or adapt the Services' software, including but not limited to Flash, PHP, HTML, JavaScript, or other code.
•   Except as permitted by applicable law, decipher, decompile, disassemble, or reverse engineer any of the 
   software comprising or in any way making up a part of the Services.<br/>
•   Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or 
   distribute any automated system, including, without limitation, any spider, robot, cheat utility, scraper, or 
   offline reader that accesses the Services, or use or launch any unauthorised script or other software.<br/>
•   Use a buying agent or purchasing agent to make purchases on the Services.<br/>
•   Make any unauthorised use of the Services, including collecting usernames and/or email addresses of users  
   by electronic or other means for the purpose of sending unsolicited emails, or creating user accounts by 
   automated means or under false pretences.<br/>
•   Use the Services as part of any effort to compete with us or otherwise use the Services and/or the Content for 
   any revenue-generating endeavour or commercial enterprise.<br/>
•   Use the Services to advertise or offer to sell goods and services.<br/>
•   Sell or otherwise transfer your profile.<br/>

    
    <h4>8. USER GENERATED CONTRIBUTIONS</h4>
    <p>The Services may invite you to chat, contribute to, or participate in blogs, message boards, online forums, and other functionality and may provide you with the opportunity to create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials to us or on the Services, including but not limited to text, writings, video, audio, photographs, graphics, comments, suggestions, or personal information or other material (collectively, 'Contributions'). Contributions may be viewable by other users of the Services and through third-party websites. As such, any Contributions you transmit may be treated as non-confidential and non-proprietary. When you create or make available any Contributions, you thereby represent and warrant that: </p>
    •   The creation, distribution, transmission, public display, or performance, and the accessing, downloading, or 
   copying of your Contributions do not and will not infringe the proprietary rights, including but not limited to 
   the copyright, patent, trademark, trade secret, or moral rights of any third party.<br/>
•   You are the creator and owner of or have the necessary licences, rights, consents, releases, and 
   permissions to use and to authorise us, the Services, and other users of the Services to use your 
   Contributions in any manner contemplated by the Services and these Legal Terms.<br/>
•   You have the written consent, release, and/or permission of each and every identifiable individual person in 
   your Contributions to use the name or likeness of each and every such identifiable individual person to 
   enable inclusion and use of your Contributions in any manner contemplated by the Services and these 
   Legal Terms.<br/>
•   Your Contributions are not false, inaccurate, or misleading.<br/>
•   Your Contributions are not unsolicited or unauthorised advertising, promotional materials, pyramid 
   schemes, chain letters, spam, mass mailings, or other forms of solicitation.<br/>
•   Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libellous, slanderous, or 
   otherwise objectionable (as determined by us).<br/>
•   Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.<br/>
•   Your Contributions are not used to harass or threaten (in the legal sense of those terms) any other person 
   or to promote violence against a specific person or class of people.<br/>
•   Your Contributions do not violate any applicable law, regulation, or rule.<br/>
•   Your Contributions do not violate the privacy or publicity rights of any third party.<br/>
•   Your Contributions do not violate any applicable law concerning child pornography or otherwise intended 
   to protect the health or well-being of minors.<br/>
•   Your Contributions do not include any offensive comments that are connected to race, national origin, 
   gender, sexual preference, or physical handicap.<br/>
•   Your Contributions do not otherwise violate or link to material that violates any provision of these Legal 
   Terms or any applicable law or regulation.
    <br/>
    
    <p>Any use of the Services in violation of the foregoing violates these Legal Terms and may result in, among other 
things, termination or suspension of your rights to use the Services.
    
    </p>
    
    <h4>9. CONTRIBUTION LICENCE</h4>
    <p>By posting your Contributions to any part of the Services, you automatically grant, and you represent and 
warrant that you have the right to grant, to us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive, 
transferable, royalty-free, fully-paid, worldwide right, and licence to host, use, copy, reproduce, disclose, sell, 
resell, publish, broadcast, retitle, archive, store, cache, publicly perform, publicly display, reformat, translate, 
transmit, excerpt (in whole or in part), and distribute such Contributions (including, without limitation, your 
image and voice) for any purpose, commercial, advertising, or otherwise, and to prepare derivative works of, 
or incorporate into other works, such Contributions, and grant and authorise sub-licenses of the foregoing. 
The use and distribution may occur in any media format and through any media channels.</p>

<p>This licence will apply to any form, media, or technology now known or hereafter developed and includes our 
use of your name, company name, and franchise name, as applicable, and any of the trademarks, service 
marks, trade names, logos, and personal and commercial images you provide. You waive all moral rights in 
your Contributions, and you warrant that moral rights have not otherwise been asserted in your Contributions.</p>

<p>We do not assert any ownership over your Contributions. You retain full ownership of all of your Contributions 
and any intellectual property rights or other proprietary rights associated with your Contributions. We are not 
liable for any statements or representations in your Contributions provided by you in any area of the Services. 
You are solely responsible for your Contributions to the Services, and you expressly agree to exonerate us from 
any and all responsibility and to refrain from any legal action against us regarding your Contributions.</p>

<p>We have the right, in our sole and absolute discretion, (1) to edit, redact, or otherwise change any 
Contributions; (2) to re-categorise any Contributions to place them in more appropriate locations on the 
Services; and (3) to pre-screen or delete any Contributions at any time and for any reason, without notice. We 
have no obligation to monitor your Contributions.</p>
    
    <h4>10. MOBILE APPLICATION LICENCE</h4>
    <h5>a. Use Licence</h5>
    <p>If you access the Services via the App, then we grant you a revocable, non-exclusive, non-transferable, 
limited right to install and use the App on wireless electronic devices owned or controlled by you, and to 
access and use the App on such devices strictly in accordance with the terms and conditions of this mobile 
application licence contained in these Legal Terms. You shall not: </p>
(1) Except as permitted by applicable law, decompile, reverse engineer, disassemble, attempt to derive the 
    source code of, or decrypt the App; <br/>
(2) Make any modification, adaptation, improvement, enhancement, translation, or derivative work from 
     the App;  <br/>
(3) Violate any applicable laws, rules, or regulations in connection with your access or use of the App; 
(4) Remove, alter, or obscure any proprietary notice (including any notice of copyright or trademark) posted 
     by us or the licensors of the App;  <br/>
(5) Use the App for any revenue-generating endeavour, commercial enterprise, or other purpose for which 
     it is not designed or intended;  <br/>
(6) Make the App available over a network or other environment permitting access or use by multiple 
     devices or users at the same time;  <br/>
(7) Use the App for creating a product, service, or software that is, directly or indirectly, competitive with or 
     in any way a substitute for the App;  <br/>
(8) Use the App to send automated queries to any website or to send any unsolicited commercial email; or  <br/>
(9) Use any proprietary information or any of our interfaces or our other intellectual property in the design, 
     development, manufacture, licensing, or distribution of any applications, accessories, or devices for use 
     with the App.<p></p>
    
    <h5>b. Apple and Android Devices</h5>
    <p>
  The following terms apply when you use the App obtained from either the Apple Store or Google Play (each 
an 'App Distributor') to access the Services: </p>
(1) The licence granted to you for our App is limited to a non-transferable licence to use the application on  
    a device that utilises the Apple iOS or Android operating systems, as applicable, and in accordance with 
    the usage rules set forth in the applicable App Distributor’s terms of service; <br/>
(2) We are responsible for providing any maintenance and support services with respect to the App as 
     specified in the terms and conditions of this mobile application licence contained in these Legal Terms 
     or as otherwise required under applicable law, and you acknowledge that each App Distributor has no 
     obligation 
     whatsoever to furnish any maintenance and support services with respect to the App;  <br/>
(3) In the event of any failure of the App to conform to any applicable warranty, you may notify the 
    applicable App Distributor, and the App Distributor, in accordance with its terms and policies, may 
    refund the 
    purchase price, if any, paid for the App, and to the maximum extent permitted by applicable law, the 
    App Distributor will have no other warranty obligation whatsoever with respect to the App;  <br/>
(4) You represent and warrant that 
    (i) you are not located in a country that is subject to a US government embargo, or that has been 
         designated by the US government as a 'terrorist supporting' country and  <br/>
    (ii) you are not listed on any US government list of prohibited or restricted parties;  <br/>
(5) You must comply with applicable third-party terms of agreement when using the App, e.g. if you have a 
     VoIP application, then you must not be in violation of their wireless data service agreement when using 
     the App; and  <br/>
(6) You acknowledge and agree that the App Distributors are third-party beneficiaries of the terms and 
     conditions in this mobile application licence contained in these Legal Terms, and that each App 
     Distributor will have the right (and will be deemed to have accepted the right) to enforce the terms and 
     conditions in this mobile application licence contained in these Legal Terms against you as a third-party 
     beneficiary thereof. <br/>
  <p></p>
    
    <h4>11. THIRD-PARTY WEBSITES AND CONTENT</h4>
    
  <p>By posting your Contributions to any part of the Services, you automatically grant, and you represent and 
warrant that you have the right to grant, to us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive, 
transferable, royalty-free, fully-paid, worldwide right, and licence to host, use, copy, reproduce, disclose, sell, 
resell, publish, broadcast, retitle, archive, store, cache, publicly perform, publicly display, reformat, translate, 
transmit, excerpt (in whole or in part), and distribute such Contributions (including, without limitation, your 
image and voice) for any purpose, commercial, advertising, or otherwise, and to prepare derivative works of, 
or incorporate into other works, such Contributions, and grant and authorise sub-licenses of the foregoing. 
The use and distribution may occur in any media format and through any media channels.</p>

<p>This licence will apply to any form, media, or technology now known or hereafter developed and includes our 
use of your name, company name, and franchise name, as applicable, and any of the trademarks, service 
marks, trade names, logos, and personal and commercial images you provide. You waive all moral rights in 
your Contributions, and you warrant that moral rights have not otherwise been asserted in your Contributions.</p>

<p>We do not assert any ownership over your Contributions. You retain full ownership of all of your Contributions 
and any intellectual property rights or other proprietary rights associated with your Contributions. We are not 
liable for any statements or representations in your Contributions provided by you in any area of the Services. 
You are solely responsible for your Contributions to the Services, and you expressly agree to exonerate us from 
any and all responsibility and to refrain from any legal action against us regarding your Contributions.</p>

<p>We have the right, in our sole and absolute discretion, </p>
(1) to edit, redact, or otherwise change any Contributions; <br/>
(2) to re-categorise any Contributions to place them in more appropriate locations on the Services; and <br/>
(3) to pre-screen or delete any Contributions at any time and for any reason, without notice. We have no 
     obligation to monitor your Contributions.<br/>
  <p></p>
    
    <h4>12. SERVICES MANAGEMENT</h4>
    <p>We reserve the right, but not the obligation, to </p>
(1)  Monitor the Services for violations of these Legal Terms; <br/>
(2) Take appropriate legal action against anyone who, in our sole discretion, violates the law or these Legal 
     Terms, including without limitation, reporting such user to law enforcement authorities;  <br/>
(3) In our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to 
     the extent technologically feasible) any of your Contributions or any portion thereof;  <br/>
(4) In our sole discretion and without limitation, notice, or liability, to remove from the Services or otherwise 
     disable all files and content that are excessive in size or are in any way burdensome to our systems, and  <br/>
(5) Otherwise manage the Services in a manner designed to protect our rights and property and to facilitate 
     the proper functioning of the Services. <br/> <p></p>
    
    <h4>13. PRIVACY POLICY</h4>
    <p>We care about data privacy and security. Please review our Privacy Policy: http://www.efluent.in/privacy . By using the Services, you agree to be bound by our Privacy Policy, which is incorporated into these Legal Terms. Please be advised the Services are hosted in India. If you access the Services from any other region of the world with laws or other requirements governing personal data collection, use, or disclosure that differ from applicable laws in India, then through your continued use of the Services, you are transferring your data to India, and you expressly consent to have your data transferred to and processed in India.
</p>
    
    <h4>14. COPYRIGHT INFRINGEMENTS</h4>
    <p>We respect the intellectual property rights of others. If you believe that any material available on or through the Services infringes upon any copyright you own or control, please immediately notify us using the contact information provided below (a 'Notification'). A copy of your Notification will be sent to the person who posted or stored the material addressed in the Notification. Please be advised that pursuant to applicable law you may be held liable for damages if you make material misrepresentations in a Notification. Thus, if you are not sure that material located on or linked to by the Services infringes your copyright, you should consider first contacting an attorney.</p>
    
    <h4>15. TERM AND TERMINATION</h4>
    <p>These Legal Terms shall remain in full force and effect while you use the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.</p>

<p>If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party. In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including, without limitation, pursuing civil, criminal, and injunctive redress.
</p>
    
    <h4>16. MODIFICATIONS AND INTERRUPTIONS</h4>
    <p>We reserve the right to change, modify, or remove the contents of the Services at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information on our Services. We also reserve the right to modify or discontinue all or part of the Services without notice at any time. We will not be liable to you or any third party for any modification, price change, suspension, or discontinuance of the Services.</p>

<p>We cannot guarantee the Services will be available at all times. We may experience hardware, software, or other problems or need to perform maintenance related to the Services, resulting in interruptions, delays, or errors. We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Services at any time or for any reason without notice to you. You agree that we have no liability whatsoever for any loss, damage, or inconvenience caused by your inability to access or use the Services during any downtime or discontinuance of the Services. Nothing in these Legal Terms will be construed to obligate us to maintain and support the Services or to supply any corrections, updates, or releases in connection therewith.
</p>
    
    <h4>17. GOVERNING LAW</h4>
    <p>These Legal Terms shall be governed by and defined following the laws of India. Simran Shri Shri International Pvt. Ltd. and you irrevocably consent that the courts of India shall have exclusive jurisdiction to resolve any dispute that may arise in connection with these Legal Terms.
</p>
    
    <h4>18. DISPUTE RESOLUTION</h4>
<h5>a. Binding Arbitration</h5>
    <p>Any dispute arising out of or in connection with these Legal Terms, including any question regarding its existence, validity, or termination, shall be referred to and finally resolved by the International Commercial Arbitration Court under the European Arbitration Chamber (Belgium, Brussels, Avenue Louise, 146) according to the Rules of this ICAC, which, as a result of referring to it, is considered as the part of this clause. The number of arbitrators shall be three (3). The seat, or legal place, or arbitration shall be Noida, India. The language of the proceedings shall be English and Hindi. The governing law of these Legal Terms shall be the substantive law of India.</p>
  <h5>b. Restrictions</h5>
    <p>The Parties agree that any arbitration shall be limited to the Dispute between the Parties individually. To the full extent permitted by law, (a) no arbitration shall be joined with any other proceeding; (b) there is no right or authority for any Dispute to be arbitrated on a class-action basis or to utilise class-action procedures; and (c) there is no right or authority for any Dispute to be brought in a purported representative capacity on behalf of the general public or any other persons.</p>	

<h5>c. Exceptions to Arbitration</h5>
    <p>The Parties agree that the following Disputes are not subject to the above provisions concerning binding 
arbitration:  </p>
(a) Any Disputes seeking to enforce or protect, or concerning the validity of, any of the intellectual property 
     rights of a Party; <br/>
(b) Any Dispute related to, or arising from, allegations of theft, piracy, invasion of privacy, or unauthorised 
     use; and <br/>
(c) Any claim for injunctive relief. <br/>
If this provision is found to be illegal or unenforceable, then neither Party will elect to arbitrate any Dispute 
falling within that portion of this provision found to be illegal or unenforceable and such Dispute shall be 
decided by a court of competent jurisdiction within the courts listed for jurisdiction above, and the Parties 
agree to submit to the personal jurisdiction of that court.<br/><p></p>

    <h4>19. CORRECTIONS</h4>
    <p>There may be information on the Services that contains typographical errors, inaccuracies, or omissions, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the Services at any time without prior notice.</p>
    
    <h4>20. DISCLAIMER</h4>
    <p>THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SERVICES 
WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR 
IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED 
WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO 
WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES' CONTENT OR THE 
CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE WILL ASSUME NO LIABILITY 
        OR RESPONSIBILITY FOR ANY </p>
(1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, <br/>
(2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO 
    AND USE OF THE SERVICES,  <br/>
(3) ANY UNAUTHORISED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL 
     INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN,  <br/>
(4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES,  <br/>
(5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES 
     BY ANY THIRD PARTY, AND/OR  <br/>
(6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND 
     INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA 
THE SERVICES. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR 
SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED WEBSITE, OR ANY 
WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY 
TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY 
PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM 
OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGEMENT AND EXERCISE CAUTION WHERE APPROPRIATE. <br/><p></p>
    
    <h4>22. INDEMNIFICATION</h4>
    <p>You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneys’ fees and expenses, made by any third party due to or arising out of: (1) your Contributions; (2) use of the Services; (3) breach of these Legal Terms; (4) any breach of your representations and warranties set forth in these Legal Terms; (5) your violation of the rights of a third party, including but not limited to intellectual property rights; or (6) any overt harmful act toward any other user of the Services with whom you connected via the Services. Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defence and control of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our defence of such claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding that is subject to this indemnification upon becoming aware of it.</p>

<h4>23. USER DATA</h4>
    <p>We will maintain certain data that you transmit to the Services for the purpose of managing the performance of the Services, as well as data relating to your use of the Services. Although we perform regular routine backups of data, you are solely responsible for all data that you transmit or that relates to any activity you have undertaken using the Services. You agree that we shall have no liability to you for any loss or corruption of any such data, and you hereby waive any right of action against us arising from any such loss or corruption of such data.</p>

<h4>24. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</h4>
    <p>Visiting the Services, sending us emails, and completing online forms constitute electronic communications. You consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email and on the Services satisfy any legal requirement that such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SERVICES. You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an original signature or delivery or retention of non-electronic records, or to payments or the granting of credits by any means other than electronic means.</p>

<h4>25. MISCELLANEOUS
</h4>
    <p>These Legal Terms and any policies or operating rules posted by us on the Services or in respect to the Services constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any right or provision of these Legal Terms shall not operate as a waiver of such right or provision. These Legal Terms operate to the fullest extent permissible by law. We may assign any or all of our rights and obligations to others at any time. We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control. If any provision or part of a provision of these Legal Terms is determined to be unlawful, void, or unenforceable, that provision or part of the provision is deemed severable from these Legal Terms and does not affect the validity and enforceability of any remaining provisions. There is no joint venture, partnership, employment or agency relationship created between you and us as a result of these Legal Terms or use of the Services. You agree that these Legal Terms will not be construed against us by virtue of having drafted them. You hereby waive any and all defences you may have based on the electronic form of these Legal Terms and the lack of signing by the parties hereto to execute these Legal Terms.
</p>
<h4>26. CONTACT US</h4>
    <p>In order to resolve a complaint regarding the Services or to receive further information regarding use of the Services, please contact us at:</p>

<p>
  <strong>
  Simran Shri Shri International Pvt. Ltd.<br/>
1st Floor, G-24<br/>
Sector-6<br/>
Noida, Uttar Pradesh 201301<br/>
India<br/>
help@efluent.in<br/>

  </strong>
</p>


    
</section>
      </div>
    
      <Footer />
    </div>
  );
}

export default TermsConditons;
