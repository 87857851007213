import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Component/Home';
import PrivacyPolicy from './Component/PrivacyPolicy';
import TermsConditons from './Component/TermsConditions';
import DeleteAccount from './Component/DeleteAccount';
import { ToastContainer } from 'react-toastify';
import Contactus from './Component/Contactus';
import Aboutus from './Component/Aboutus';
import JoinUs from './Component/JoinUs';
import BarCode from './Component/BarCode';
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-conditions" element={<TermsConditons />} />
          <Route path="/delete-account" element={<DeleteAccount />} />
          <Route path="/contact-us" element={<Contactus />} />
          <Route path="/about-us" element={<Aboutus />} />
          <Route path="/join-us" element={<JoinUs />} />
          <Route path="/representative1" element={<BarCode />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
export default App;
