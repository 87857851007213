import React from "react";
// import CallDetail from '../images/call-history.webp';
import Download from "../images/download.webp";
import { APP_LINK } from "../utils/constants";

function Banner() {
  return (
    <>
      <section className="bannerHolder">
        <div className="wrapper">
          <div className="bannerBox">
            <div className="BannerLeft">
              <h1>
                efluent: Helps Practice English Speaking in an Engaging Way!{" "}
              </h1>
              <p>
              With ease of conversation and user-friendly features, you get the opportunity to practice English speaking from the comfort of your home via progressive conversation modules. An enriching user experience via gaming and self-paced courses provides a holistic learning experience. Join us for a comprehensive and rewarding learning journey. Hit the bull’s eye and be a master of spoken English!
              </p>
              <a href={APP_LINK} target="_blank">
                <img src={Download} alt="" />
              </a>
            </div>
            <div className="BannerRight">
              {/* <img src={CallDetail} alt=""/> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Banner;
