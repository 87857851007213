import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/logo.webp';

function Navbar() {

    const [nav,setnav]= useState(false);

    const changeBackground = () => {
        if(window.scrollY >= 50) {
            setnav(true);
        }
        else{
            setnav(false);
        }
    }

    window.addEventListener('scroll', changeBackground);

    return (
        <div className='wrapper'>
 <nav className={nav ? 'nav active' : 'nav'}>
 {/* <nav className='nav active'> */}
            <a href='/' className='logo'>
                <img src={logo} alt="" />
            </a>
            <input type='checkbox' className='menu-btn' id='menu-btn'/>
            <label className='menu-icon' for='menu-btn'>
                <span className='nav-icon'></span>
            </label>
            <ul className='menu'>
                <li><a href='/'>Home</a></li>
                <li><a href='/#feature'>Feature</a></li>
                <li><a href='/#whyChoosus'>Why Choose Us</a></li>
              
                
                <li><a href='/#contact-us'>Contact</a></li>
                <li><a href='/#reviews'>Review</a></li>
            </ul>
        </nav>
        </div>
       
    );
}
export default Navbar;
